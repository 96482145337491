import React from 'react'
import PropTypes from 'prop-types'
import htmr from 'htmr'

const FeaturesCard = ({ icon, title, description }) => {
    return (
        <div className="features-card">
            <div className="features-card__icon">
                <img src={icon} alt="feature icon" />
            </div>
            <div className="features-card__headline">
                <h1>{htmr(title)}</h1>
            </div>
            <div className="features-card__description">
                <p className="paragraph paragraph--lg paragraph--s paragraph--dark">
                    {htmr(description)}
                </p>
            </div>
            <div className="divider divider--md divider--thick divider--blue" />
        </div>
    )
}

FeaturesCard.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default FeaturesCard
