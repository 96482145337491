import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import {
    ContentHeader,
    SectionHeader,
    Section,
    Container,
} from '../components/Common'
import { MainHero } from '../components/Hero'
import { Features } from '../components/Features'
import { Benefits } from '../components/Benefits'
import BookBanner from '../components/BookBanner/BookBanner'
import { PartnersSection } from '../components/Partners'

const serviceTemplate = ({
    data: {
        wpgraphql: { service, partners },
    },
}) => {
    const { acf_serviceContent, acf_hero, acf_bookCTA, title } = service

    const hero = {
        image: acf_hero.heroImage.sourceUrlSharp.childImageSharp.fluid,
        alt: acf_hero.heroImage.altText,
        description: acf_hero.bbDescription,
        title: acf_hero.bbTitle,
    }
    const bookBanner = {
        image: acf_bookCTA.image.sourceUrlSharp.childImageSharp.fluid,
        alt: acf_bookCTA.image.altText,
        title: acf_bookCTA.title,
    }

    return (
        <Layout>
            <SEO title={title} />

            <MainHero
                title={hero.title}
                description={hero.description}
                image={hero.image}
                alt={hero.alt}
            />

            <ContentHeader
                title={acf_serviceContent.mainTitle}
                description={acf_serviceContent.mainDescription}
            />

            <Features features={acf_serviceContent.features} />

            <Benefits benefits={acf_serviceContent.benefits} />

            <Section>
                <Container>
                    <SectionHeader>CERTIFIED PARTNERS</SectionHeader>
                    <PartnersSection partners={partners} />
                </Container>
            </Section>

            <BookBanner
                image={bookBanner.image}
                alt={bookBanner.alt}
                title={bookBanner.title}
            />
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        wpgraphql {
            service: serviceBy(slug: $slug) {
                title
                acf_hero {
                    bbDescription
                    bbTitle
                    heroImage {
                        altText
                        sourceUrl
                        sourceUrlSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 1440) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                }
                            }
                        }
                    }
                }
                acf_bookCTA {
                    title
                    url {
                        url
                    }
                    image {
                        altText
                        sourceUrl
                        sourceUrlSharp {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 1200) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                }
                            }
                        }
                    }
                }
                acf_serviceContent {
                    mainTitle
                    mainDescription
                    features {
                        icon {
                            sourceUrl
                        }
                        title
                        description
                    }
                    benefits {
                        title
                        description
                    }
                }
            }
            partners {
                edges {
                    node {
                        acf_partners {
                            logo {
                                alt
                                image {
                                    altText
                                    sourceUrl
                                    sourceUrlSharp {
                                        childImageSharp {
                                            fluid(
                                                pngQuality: 100
                                                maxWidth: 175
                                            ) {
                                                base64
                                                aspectRatio
                                                src
                                                srcSet
                                                sizes
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

serviceTemplate.propTypes = {}

export default serviceTemplate
