import React from 'react'
import PropTypes, { object } from 'prop-types'
import FeaturesCard from './FeaturesCard'
import { Container, Section } from '../Common'

const Features = ({ features }) => {
    return (
        <Section>
            <Container small>
                <div className="features">
                    {features &&
                        features.map((feature, index) => (
                            <FeaturesCard
                                key={index}
                                icon={feature.icon.sourceUrl}
                                title={feature.title}
                                description={feature.description}
                            />
                        ))}
                </div>
            </Container>
        </Section>
    )
}

Features.propTypes = {
    features: PropTypes.arrayOf(object).isRequired,
}

export { Features }
