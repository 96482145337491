import React from 'react'
import PropTypes from 'prop-types'
import htmr from 'htmr'

const BenefitsCard = ({ title, description }) => {
    return (
        <div className="benefits__card">
            <div className="benefits__card-title">{htmr(title)}</div>
            <div className="benefits__card-description">
                <p className="paragraph paragraph--lg paragraph--dark">
                    {htmr(description)}
                </p>
            </div>
        </div>
    )
}

BenefitsCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default BenefitsCard
