import React from 'react'
import PropTypes, { object } from 'prop-types'
import { Section, Container } from '../Common'
import BenefitsCard from './BenefitsCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Pagination])

const Benefits = ({ benefits }) => {
    return (
        <>
            <Section dark className={'hide-down--sm'}>
                <Container>
                    <div className="benefits">
                        <div className="benefits__title">KEY BENEFITS</div>
                        <div className="benefits__cards">
                            {benefits &&
                                benefits.map((benefit, index) => (
                                    <BenefitsCard
                                        title={benefit.title}
                                        description={benefit.description}
                                    />
                                ))}
                        </div>
                    </div>
                </Container>
            </Section>

            <Section dark className={'hide-up--sm'}>
                <Container>
                    <div className="benefits">
                        <div className="benefits__title">KEY BENEFITS</div>
                        <div className="benefits__cards">
                            <Swiper slidesPerView={1} pagination>
                                {benefits &&
                                    benefits.map((benefit, index) => (
                                        <>
                                            <SwiperSlide>
                                                <BenefitsCard
                                                    title={benefit.title}
                                                    description={
                                                        benefit.description
                                                    }
                                                />
                                            </SwiperSlide>
                                        </>
                                    ))}
                            </Swiper>
                        </div>
                    </div>
                </Container>
            </Section>
        </>
    )
}

Benefits.propTypes = {
    benefits: PropTypes.arrayOf(object).isRequired,
}

export { Benefits }
